export const stripDashes = string => string.replace(/-/g, '');

export const toCamel = o => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function(value) {
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = origKey.toString();
        if (origKey.indexOf('-') !== -1) {
          newKey = (
            origKey.slice(0, origKey.indexOf('-')) +
            origKey.slice(origKey.indexOf('-') + 1)
          ).toString();
        }

        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const capitalize = string =>
  string
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substr(1))
    .join(' ');

export function debounce(func, wait, immediate) {
  let timeout;

  return function() {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const throttle = (callback, delay = 200) => {
  let lastCall = 0;
  return function(...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    callback(...args);
  };
};

// https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
export function objectMap(object, mapFunction) {
  return Object.keys(object).reduce(function(result, key) {
    result[key] = mapFunction(object[key]);
    return result;
  }, {});
}

export const getObjectValueByPath = (object, path) =>
  path.split('.').reduce((acc = {}, curr) => acc[curr], object);

export function setObjectPropertyByPath(object, path, value) {
  path = path.split('.');

  while (path.length > 1) {
    const position = path.shift();
    if (!object[position]) object[position] = {};
    object = object[position];
  }

  return (object[path.shift()] = value);
}

export const cloneObject = object => JSON.parse(JSON.stringify(object));

// See explanation here: https://stackoverflow.com/questions/4994201/is-object-empty
export const isEmptyObject = obj =>
  Object.getOwnPropertyNames(obj).length === 0;

export const convertContentArrayToString = contentArray =>
  contentArray.reduce((acc, curr) => {
    acc += curr.props.children;
    return acc;
  }, '');

export const convertStringToBoolean = value =>
  value ? value === 'true' || value === 'Y' : null;

export const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => obj1[key] == obj2[key]); // eslint-disable-line eqeqeq

const agencyProirity = {
  dmv: 1,
  dps: 2,
  tdlr: 3,
};

export const sortAgencies = (a1, a2) => {
  let agency1 = a1.agencySlug || a1.path.split('/').pop();
  let agency2 = a2.agencySlug || a2.path.split('/').pop();
  let p1 = agencyProirity[agency1];
  let p2 = agencyProirity[agency2];
  return p1 - p2;
};

// accepts 'mm/yy' format
export const isDateExpired = expirationDate => {
  if (!expirationDate) return false;
  const [month, year] = expirationDate.split('/').map(Number);
  const currentDate = new Date();
  const expiration = new Date(year + 2000, month);
  return expiration < currentDate;
};

/**
 * Wraps a function with a timeout, allowing it to be cancelled if it takes too long to execute.
 * @param {Function} fn - The function to be wrapped.
 * @param {number} timeout - The timeout duration in milliseconds.
 * @param {Error} error - The error to be thrown if the timeout is reached.
 * @returns {Function} - The wrapped function.
 */
export const cancelCallAfterTimeout = (fn, timeout, error) => {
  return (...args) => {
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(reject, timeout, error)
    );
    return Promise.race([fn(...args), timeoutPromise]);
  };
};

/**
 * Retries a function a specified number of times if it throws an exception.
 * @param {Function} fn - The function to call.
 * @param {number} maxAttempts - The maximum number of attempts.
 * @param {number} retryDelay - The delay between attempts in milliseconds.
 * @returns {Promise} - The result of the function.
 */
export const retryOnException = (fn, maxAttempts = 3, retryDelay = 1000) => {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    const execute = async () => {
      try {
        const result = await fn();
        resolve(result);
      } catch (error) {
        if (++attempts < maxAttempts) {
          setTimeout(execute, retryDelay);
        } else {
          console.error('Retry on exception failed', error);
          reject(error);
        }
      }
    };

    execute();
  });
};

export const convertNullsToEmptyStrings = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = obj[key] === null ? '' : obj[key];
    if (typeof acc[key] === 'string') {
      acc[key] = acc[key].trim();
    }
    return acc;
  }, {});
};
